'use client';

import { useState } from 'react';
import { useParams, useRouter } from 'next/navigation';
import { useGetAuthorArticlesQuery } from '@/RTK/services/articleApi';
import {
  useGetAuthorByIdQuery,
  useGetAuthorByUsernameQuery,
  useGetFollowersQuery,
  useGetFollowingsQuery,
  useGetUserQuery,
} from '@/RTK/services/userApi';
import Loader from '@/utils/Loader';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import ExploreArticleCard from '../../components/ExploreArticleCard';
import MyPostsPage from '../../feed/my-posts/myPosts';
import AuthorHeader from '../components/AuthorHeader';
import AuthorSidebar from '../components/AuthorSidebar';
import { AuthorSkeleton } from '../components/SkeletonLoader';

const Author = () => {
  const options = [
    'الأفكار',
    'النصوص',
    //  'الكتب',
    'عن الكاتب',
  ];
  const [selectedOption, setSelectedOption] = useState('الأفكار');
  const [copy, setCopy] = useState(false);
  const { authorUsername } = useParams();
  const username = authorUsername.replace(/%40/g, '@').split('@')[1];
  const token = useSelector((state) => state.auth.token);

  console.log('username', username);
  // queries
  const { data: currentUser } = useGetUserQuery(undefined, {
    skip: !token,
  });
  // const currentUserId = currentUser?.user?._id || null
  const { data: authorData, isLoading: isAuthorLoading } =
    useGetAuthorByUsernameQuery(username);

  const { data, isLoading: isArticlesLoading } = useGetAuthorArticlesQuery(
    authorData?.user?._id,
    {
      skip: !authorData?.user?._id,
    }
  );

  const { data: followersData } = useGetFollowersQuery(authorData?.user?._id, {
    skip: !authorData?.user?._id,
  });
  const { data: followingsData } = useGetFollowingsQuery(
    authorData?.user?._id,
    {
      skip: !authorData?.user?._id,
    }
  );
  const isLoggedIn = useSelector((state) => state.auth.token);
  const handleCopy = () => {
    const url = window.location.origin + `/@${authorData?.user?.username}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopy(true); // Update the copy state
        toast.success('!Copied');
        setTimeout(() => setCopy(false), 2000); // Reset copy state after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying URL:', error);
      });
  };
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {isAuthorLoading || isArticlesLoading ? (
        <AuthorSkeleton />
      ) : (
        <div className="flex flex-col lg:flex-row gap-8 py-8">
          {/* Main Content */}
          <div className="flex-1 min-w-0">
            {/* Author Header - Mobile */}
            <div className="lg:hidden space-y-6 mb-8">
              <AuthorHeader
                author={authorData}
                followersCount={followersData?.followers?.length}
                followingsCount={followingsData?.following?.length}
                socials={authorData?.user?.socials}
                onCopy={handleCopy}
                copy={copy}
                isLoggedIn={isLoggedIn}
                currentUser={currentUser}
              />
            </div>

            {/* Content Tabs */}
            <nav className="border-b border-gray-200 mb-8">
              <div className="flex gap-8">
                {options.map((option) => (
                  <button
                    key={option}
                    onClick={() => setSelectedOption(option)}
                    className={`
                      py-4 px-1 relative
                      text-sm font-medium
                      ${
                        selectedOption === option
                          ? 'text-purple-600 border-b-2 border-purple-600'
                          : 'text-gray-500 hover:text-gray-700'
                      }
                      transition-colors
                    `}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </nav>

            {/* Tab Content */}
            <div className="space-y-6">
              {selectedOption === 'النصوص' && (
                <div className="grid gap-6">
                  {data?.articles
                    ?.filter((article) => article.status === 'published')
                    .map((article, index) => (
                      <ExploreArticleCard
                        key={index}
                        article={article}
                        className="hover:bg-gray-50 transition-colors rounded-xl p-4"
                      />
                    ))}
                </div>
              )}

              {selectedOption === 'عن الكاتب' && (
                <div className="prose prose-purple max-w-none">
                  <p className="text-gray-700 leading-relaxed">
                    {authorData?.user?.bio || 'لا يوجد وصف للكاتب'}
                  </p>
                </div>
              )}

              {selectedOption === 'الأفكار' && (
                <MyPostsPage author={authorData} />
              )}
            </div>
          </div>

          {/* Sidebar */}
          <div className="hidden lg:block w-80 flex-shrink-0">
            <div className="sticky top-24 space-y-8">
              <AuthorSidebar
                author={authorData}
                followersCount={followersData?.followers?.length}
                followingsCount={followingsData?.following?.length}
                socials={authorData?.user?.socials}
                onCopy={handleCopy}
                copy={copy}
                username={username}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Author;
