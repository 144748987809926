import {
  Crown,
  Facebook,
  Globe,
  Instagram,
  Linkedin,
  Link as LinkIcon,
  X,
  Youtube,
} from 'lucide-react';

import { cn } from '@/lib/utils';
import { useFollow } from '@/hooks/useFollow';
import { Button } from '@/components/ui/button';
import ProfileImage from '@/components/ui/profile-image';

import Loader from '../../../../../utils/Loader';
import SocialLinks from './SocialLinks';

const AuthorHeader = ({
  author,
  followersCount,
  followingsCount,
  socials,
  onCopy,
  copy,
}) => {
  const { isFollowing, handleFollow, canFollow, isLoading } = useFollow(
    author?.user?._id
  );

  return (
    <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
      <div className="flex items-start gap-4">
        {/* Author Image */}
        <ProfileImage
          userName={author?.user?.name}
          src={author?.user?.profileImage}
          alt={author?.user?.name}
          size="lg"
          isPremium={author?.user?.isPremium}
        />

        {/* Author Info */}
        <div className="flex-1 min-w-0">
          <h1 className="text-xl font-semibold text-gray-900 truncate">
            {author?.user?.name}
          </h1>
          <p className="mt-1 text-sm text-gray-500">
            @{author?.user?.username}
          </p>

          <div className="mt-4 flex items-center gap-4 text-sm">
            <span className="text-gray-600">
              <strong className="text-gray-900">{followersCount || 0}</strong>{' '}
              متابع
            </span>
            <span className="text-gray-600">
              <strong className="text-gray-900">{followingsCount || 0}</strong>{' '}
              يتابع
            </span>
          </div>
        </div>
      </div>

      {/* Actions */}
      <div className="mt-6 flex items-center gap-3">
        {canFollow && (
          <Button
            onClick={handleFollow}
            disabled={isLoading}
            className={cn(
              'flex-1 transition-colors',
              isFollowing
                ? 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                : 'bg-purple-600 hover:bg-purple-700 text-white'
            )}
          >
            {isLoading ? (
              <span className="flex items-center gap-2">
                <Loader size="sm" />
                جارٍ التحديث...
              </span>
            ) : isFollowing ? (
              'إلغاء المتابعة'
            ) : (
              'متابعة'
            )}
          </Button>
        )}

        {/* Social Links */}
        <SocialLinks
          socials={socials}
          onCopy={onCopy}
          copy={copy}
          author={author}
          showProfileCard={true}
        />
      </div>
    </div>
  );
};

export default AuthorHeader;
