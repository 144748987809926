'use client';

import React, { useEffect, useState } from 'react';
import { useGetPostsByUserIdQuery } from '@/RTK/services/postsApi';
import { useGetUserQuery } from '@/RTK/services/userApi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Virtuoso } from 'react-virtuoso';

import { TweetSkeleton } from '../components/skeletons/PostSkeletonLoader';
import Tweet from '../components/Tweet';

import '../animation/style.css';

function MyPostsPage({ author }) {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const { data: user, isLoading: userLoading } = useGetUserQuery(undefined);
  let id = author?.user?._id || user?.user?._id;
  const {
    data: myPosts,
    isLoading: isMyPostsLoading,
    error: myPostsError,
  } = useGetPostsByUserIdQuery(
    { page, userId: author?.user?._id || user?.user?._id },
    {
      skip: !author && userLoading,
    }
  );

  const fetchPosts = async () => {
    if (isMyPostsLoading || !hasMore) return;
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (myPosts && myPosts?.posts?.length === myPosts?.pagination?.totalPosts) {
      setHasMore(false);
    }
  }, [myPosts?.posts.length, isMyPostsLoading]);

  useEffect(() => {
    return () => {
      setPage(1);
      setHasMore(true);
    };
  }, []);
  if (
    !myPosts ||
    (isMyPostsLoading && myPosts.posts.length === 0) ||
    userLoading
  ) {
    return (
      <div className="w-full max-w-[516px] mx-auto">
        {Array.from({ length: 5 }).map((_, index) => (
          <TweetSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (myPostsError) {
    return (
      <div className="flex items-center justify-center h-[300px]">
        <p className="text-red-500 text-center">
          An error occurred while fetching your posts
        </p>
      </div>
    );
  }

  return (
    <main className="flex flex-col w-full ">
      <div className="w-full max-w-[516px] mx-auto  overflow-hidden">
        {myPosts?.posts.length === 0 ? (
          <div className="flex items-center justify-center ">
            <p className="text-gray-500 text-center">
              You haven't created any posts yet
            </p>
          </div>
        ) : (
          <div className="mb-16">
            {/* <InfiniteScroll
              dataLength={myPosts?.posts.length}
              next={fetchPosts}
              hasMore={hasMore}
              loader={<TweetSkeleton />}
              endMessage={
                <p className="text-center p-3">No more posts to load</p>
              }
              refreshFunction={fetchPosts}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              pullDownToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>
                  &#8595; Pull down to refresh
                </h3>
              }
              releaseToRefreshContent={
                <h3 style={{ textAlign: 'center' }}>
                  &#8593; Release to refresh
                </h3>
              }
            >
              {myPosts?.posts.map((tweet) => (
                <Tweet key={tweet?._id} tweet={tweet} user={user} />
              ))}
            </InfiniteScroll> */}
            <Virtuoso
              useWindowScroll
              data={myPosts?.posts}
              endReached={fetchPosts}
              increaseViewportBy={{ top: 1000, bottom: 1000 }}
              itemContent={(_, tweet) => (
                <Tweet key={tweet?._id} tweet={tweet} user={user} />
              )}
            />
          </div>
        )}
      </div>
    </main>
  );
}

export default MyPostsPage;
