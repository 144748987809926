'use client';

import { useState } from 'react';
import { useGetUserQuery } from '@/RTK/services/userApi';
import {
  Globe,
  Instagram,
  Linkedin,
  Link as LinkIcon,
  UserSquare2,
  X,
} from 'lucide-react';
import { useSelector } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';

import ProfileCard from './ProfileCard';

const SocialLinks = ({
  socials,
  onCopy,
  copy,
  author,
  showProfileCard = false,
}) => {
  const token = useSelector((state) => state.auth.token);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: currentUser } = useGetUserQuery(undefined, { skip: !token });

  return (
    <>
      <div className="flex flex-wrap justify-center items-center gap-2 mt-2">
        {socials?.twitter && (
          <a
            href={socials.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 text-gray-400 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-all"
          >
            <X className="w-5 h-5" />
          </a>
        )}
        {socials?.instagram && (
          <a
            href={socials.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 text-gray-400 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-all"
          >
            <Instagram className="w-5 h-5" />
          </a>
        )}
        {socials?.linkedin && (
          <a
            href={socials.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 text-gray-400 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-all"
          >
            <Linkedin className="w-5 h-5" />
          </a>
        )}
        {socials?.website && (
          <a
            href={socials.website}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 text-gray-400 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-all"
          >
            <Globe className="w-5 h-5" />
          </a>
        )}
        {showProfileCard && currentUser?.user?._id === author?.user?._id && (
          <button
            onClick={() => setIsDialogOpen(true)}
            className="p-2 text-gray-400 hover:text-purple-600 hover:bg-purple-50 rounded-full transition-all"
            title="عرض البطاقة الشخصية"
          >
            <UserSquare2 className="w-7 h-7" />
          </button>
        )}
        <button
          onClick={onCopy}
          className={`p-2 rounded-full transition-all ${
            copy
              ? 'text-green-500 bg-green-50'
              : 'text-gray-400 hover:text-purple-600 hover:bg-purple-50'
          }`}
        >
          <LinkIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Profile Card Dialog */}
      {showProfileCard && (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="max-w-md max-h-[90vh] overflow-hidden flex flex-col p-4">
            <DialogTitle className="sr-only">
              البطاقة الشخصية - {author?.user?.name}
            </DialogTitle>
            <div className="overflow-y-auto flex-1 scrollbar px-4">
              <ProfileCard author={author} />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SocialLinks;
