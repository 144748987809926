'use client';

import { useRef, useEffect, useState } from 'react';
import domtoimage from 'dom-to-image';
import { toast } from 'react-hot-toast';
import { Download } from 'lucide-react';

export default function ProfileCard({ author }) {
  const cardRef = useRef(null);
  const [logoBase64, setLogoBase64] = useState('');

  // Convert logo to base64 on component mount
  useEffect(() => {
    fetch("https://kitaba-files.s3.amazonaws.com/test/1735912506212-N7dExQpnxgQqH78A5XKb0.png")
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => setLogoBase64(reader.result);
        reader.readAsDataURL(blob);
      })
      .catch(error => console.error('Error loading logo:', error));
  }, []);

  const downloadCard = async () => {
    if (!cardRef.current) {
      toast.error('لم يتم العثور على البطاقة');
      return;
    }

    try {
      // Wait for images to load
      await new Promise(resolve => setTimeout(resolve, 100));

      const scale = 2;
      const node = cardRef.current;
      
      const style = {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left',
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px"
      }

      const param = {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        quality: 1,
        style
      }

      // Generate image
      const dataUrl = await domtoimage.toPng(node, param);

      // Download image
      const link = document.createElement('a');
      link.download = `${author?.user?.username || 'profile'}-card.png`;
      link.href = dataUrl;
      link.click();

      toast.success('تم تحميل البطاقة بنجاح');
    } catch (err) {
      console.error('Error details:', err);
      toast.error('حدث خطأ أثناء تحميل البطاقة. يرجى المحاولة مرة أخرى');
    }
  };

  return (
    <div className="w-full max-w-sm mx-auto">
      <div
        ref={cardRef}
        className="bg-white rounded-sm shadow-sm border border-purple-100 p-6 sm:p-8 text-center relative overflow-hidden"
      >
        {/* Profile Image */}
        <div className="relative w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 mx-auto mb-4 sm:mb-6">
          <div className="absolute inset-0 bg-gradient-to-b from-purple-100 to-purple-50 rounded-full"></div>
          <img
            src={
              author?.user?.profileImage ||
              `https://ui-avatars.com/api/?name=${encodeURIComponent(author?.user?.username)}&background=8b5cf6&color=fff`
            }
            alt="Profile"
            className="w-full h-full object-cover rounded-full ring-4 ring-white shadow-sm relative z-10"
            crossOrigin="anonymous"
          />
        </div>

        {/* Username Tag
        <div className="inline-block bg-purple-50 rounded-full px-4 py-1 text-sm text-purple-600 font-medium mb-3">
          @{author?.user?.username}
        </div> */}

        {/* Name */}
        <h2 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2 sm:mb-3">
          {author?.user?.name || author?.user?.username}
        </h2>

        {/* Bio */}
        {author?.user?.bio && (
          <p className="text-gray-600 text-sm leading-relaxed max-w-xs mx-auto line-clamp-2 mb-3">
            {author?.user?.bio}
          </p>
        )}

        {/* Follow Me Text */}
        <div className="inline-block bg-purple-50 rounded-full px-4 py-1 text-sm text-purple-600 font-medium ">
          تابعني على كتابة
        </div>

        {/* Platform Link with Transparent Logo */}
        <div className="flex flex-col items-center justify-center gap-1">
          {logoBase64 && (
            <img 
              src={logoBase64}
              alt="Kitabh Logo" 
              className="object-contain w-[80px] h-[80px] opacity-70"
            />
          )}
          <div className="inline-block bg-purple-50 rounded-full px-4 py-1">
            <a
              href={`https://kitabh.com/@${author?.user?.username}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-purple-600 font-medium hover:text-purple-700 transition-colors"
            >
              kitabh.com/@{author?.user?.username}
            </a>
          </div>
        </div>
      </div>

      {/* Download Button */}
      <button
        onClick={downloadCard}
        className="download-button w-full mt-4 bg-white text-purple-600 border border-purple-200 
          hover:bg-purple-50 rounded-xl py-2.5 sm:py-3 px-4 flex items-center justify-center gap-2 
          transition-all duration-200 font-medium shadow-sm hover:shadow"
      >
        <Download className="w-4 h-4" />
        تحميل البطاقة
      </button>
    </div>
  );
}