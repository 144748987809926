import { Skeleton } from '@/components/ui/skeleton';

import { ExploreArticleCardSkeleton } from '../../../components/ExploreArticleCardSkeleton';

export const AuthorSkeleton = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col lg:flex-row gap-8 py-8">
        {/* Main Content Skeleton */}
        <div className="flex-1 min-w-0 space-y-8">
          {/* Tabs */}
          <div className="flex gap-8">
            {[1, 2].map((_, idx) => (
              <Skeleton key={idx} className="h-4 rounded w-24" />
            ))}
          </div>

          {/* Article Cards */}
          <div className="space-y-6">
            {[1, 2, 3].map((_, idx) => (
              <ExploreArticleCardSkeleton
                key={idx}
                className="h-32 rounded-lg"
              />
            ))}
          </div>
        </div>

        {/* Sidebar Skeleton */}
        <div className="hidden lg:block w-80 flex-shrink-0 space-y-8">
          <div className="sticky top-24 space-y-6">
            <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100 animate-pulse">
              <div className="flex flex-col items-center">
                <Skeleton className="w-16 h-16 rounded-full mb-4" />
                <Skeleton className="h-5 rounded w-1/3 mb-2" />
                <Skeleton className="h-4 rounded w-1/4" />
                <div className="mt-6 space-y-4">
                  {[1, 2].map((_, idx) => (
                    <Skeleton key={idx} className="h-6 rounded w-2/3 mx-auto" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorSkeleton;
